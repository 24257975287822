import React, { useRef } from 'react'
import Slider from "react-slick";
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from '@gsap/react';

import videosource from "../assets/video/haus-home-sequence.webm";
import uzum from "../assets/img/uzum.png";
import fao from "../assets/img/fao.png";
import undp from "../assets/img/undp.png";
import ebrd from "../assets/img/ebrd.png";
import cgiar from "../assets/img/cgiar.png";
import kursiv from "../assets/img/kursiv.png";
import hackathon1 from "../assets/img/hackathon_1.jpg";
import acceleration1 from "../assets/img/acceleration.webp";
import demoday1 from "../assets/img/demoday1.jpg";
import author from "../assets/img/sarvar.jpg";
import author2 from "../assets/img/author.jpg";
import dimadina from "../assets/img/team/dimadina2.jpg";
import aziza from "../assets/img/team/aziza.jpg";
import mahtob from "../assets/img/team/mahtob.jpg";
import mmadina from "../assets/img/team/mmadina.jpg";
import swu from "../assets/img/swu.jpg";
import techgenius from "../assets/img/techgenius.jpg";
import uzummeetup from "../assets/img/uzummeetup.jpg";
import banner from "../assets/img/startupweekend-banner.jpg";
import challenge from "../assets/img/hackathon-2.jpeg";
import customTransition from '../utils/transition';
import Header from '../components/Header';

var settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

var companiesSliderSettings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
};

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function StartupWeekend() {
    const container = useRef();

    useGSAP(
        () => {
            gsap.set(".hover-block img", {
                x: "50%",
                y: "50%",
                xPercent: -50,
                yPercent: -50,
            });

            let activeImage;
            gsap.utils.toArray(".hover-block").forEach((el) => {
                let image = el.querySelector("img"),
                    setX,
                    setY,
                    align = (e) => {
                        setX(e.clientX);
                        setY(e.clientY);
                    },
                    startFollow = () => document.addEventListener("mousemove", align),
                    stopFollow = () => document.removeEventListener("mousemove", align),
                    fade = gsap.to(image, {
                        autoAlpha: 1,
                        ease: "none",
                        paused: true,
                        duration: 0.2,
                        onReverseComplete: stopFollow,
                    });

                el.addEventListener("mouseenter", (e) => {
                    fade.play();
                    startFollow();
                    if (activeImage) {
                        gsap.set(image, {
                            x: gsap.getProperty(activeImage, "x"),
                            y: gsap.getProperty(activeImage, "y"),
                        });
                    }
                    activeImage = image;
                    setX = gsap.quickTo(image, "x", { duration: 0.6, ease: "power3" });
                    setY = gsap.quickTo(image, "y", { duration: 0.6, ease: "power3" });
                    align(e);
                });

                el.addEventListener("mouseleave", () => fade.reverse());
            });
        },
        { scope: container }
    );

    return (
        <div>
            <Header
                main={
                    <>
                        <div>Techstars</div>
                        <div className="">
                            <span className="blue">Startup</span> Weekend
                        </div>
                    </>
                }
            />
            <main ref={container}>
                <section id="about" data-color="white black">
                    <div className="container">
                        {/* <img src={banner} alt="startup weekend" style={{ width: '100%' }} /> */}
                        <div className="split-row px-xl">
                            <div>
                                <img src={banner} alt="startup weekend" style={{ width: '100%', borderRadius: 10 }} />
                                {/* <video width="750" muted autoPlay loop>
                                    <source src={videosource} type="video/mp4" />
                                </video> */}
                            </div>
                            <div>
                                <h2>
                                    {/* <span className="bg-rounded bg-blue">StartupWeekend.uz</span> */}
                                    Startup Weekend Uzbekistan
                                </h2>
                                <div className="split-content">
                                    <p>
                                        Startup Weekend is a program where aspiring entrepreneurs can experience startup life. Over an action-packed <b>three days,</b> you’ll meet the very <b>best mentors, investors, co-founders and sponsors</b> to show you how to get more done faster - and, maybe even start that Business.
                                    </p>
                                    <br />
                                    <a href="https://startupweekend.uz/" className='btn' target='_blank'>Web-site</a>
                                </div>
                            </div>
                        </div>
                        <div className='videoWrapper'>
                            <iframe width="100%" height="783" src="https://www.youtube.com/embed/mlbaSwn4mn0" title="Startup Weekend Tashkent, December 2022 | Highlights" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" style={{ borderRadius: 10 }} allowfullscreen></iframe>
                        </div>
                    </div>
                </section>
                <section id="challenge" data-color="white black">
                    <div className="container">
                        <div className="split-row">
                            <div className="half-row">
                                <div></div>
                                <div>Challenge</div>
                            </div>
                            <div>
                                <div className="split-content">
                                    <h3>
                                        The Problem We Faced
                                    </h3>
                                    <p>
                                        Create an easy to join platform where entrepreneurs can test ideas, gather teams, gain startup business fundamentals. Foster community with ongoing activity in it.
                                    </p>
                                    <br />
                                    <p>
                                        Building an environment for learning and developing entrepreneurial skills, pitching ideas, discovering the fundamentals of startup business, and creating, fostering and empowering communities through experiential learning. Developing skills in teamwork, creativity and co-operation with like-minded, innovative, motivated and passionate people.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="approach" data-color="white black">
                    <div className="container">
                        <div className="split-row">
                            <div className="half-row">
                                <div></div>
                                <div>Approach</div>
                            </div>
                            <div>
                                <div className="split-content">
                                    <h3>
                                        Our Strategic Plan
                                    </h3>
                                    <p>
                                        Knowing that growing a platform from scratch can be quite a  challenge we decided to localize existing global platforms and create a more universal entrepreneurial community. This will empower individuals everywhere to test ideas, build teams, and access business fundamentals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="solution" data-color="white black">
                    <div className="container">
                        <div className="split-row">
                            <div className="half-row">
                                <div></div>
                                <div>Solution</div>
                            </div>
                            <div>
                                <div className="split-content">
                                    <h3>
                                        The Effective Outcome
                                    </h3>
                                    <p>
                                        We chose <b>Techstars Startup Weekend</b> - a three-day intensive pre-acceleration programme where everyone can develop and present new startup ideas. It's pitching, business model creation, prototyping, design and market validation in a friendly and supportive team environment.
                                    </p>
                                    <ul>
                                        <li>
                                            <b>1 - Day</b>
                                            <div>Ice-breakers</div>
                                            <div>Pitching session</div>
                                            <div>Team formation</div>
                                        </li>
                                        <li>
                                            <b>2 - Day</b>
                                            <div>Customer validation workshop</div>
                                            <div>Mentor sessions</div>
                                            <div>Business model canvas workshop</div>
                                        </li>
                                        <li>
                                            <b>3 - Day</b>
                                            <div>Pitch deck creation workshop</div>
                                            <div>Pitch polishing sessions</div>
                                            <div>Pitch deck presentation</div>
                                            <div>Awarding</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="challenge" data-color="white black">
                    <div className="container">
                        <div className="split-row align-items-end">
                            <div className="half-row">
                                <div></div>
                                <div>Contact us</div>
                            </div>
                            <div>
                                <h2 className="">Get in Touch</h2>
                            </div>
                        </div>
                        <div className="split-row">
                            <div className='half-row'></div>
                            <div>
                                <div className="split-content">
                                    <br />
                                    <h4 className="">
                                        Have an event in mind? We’re here to help bring it to life.
                                    </h4>
                                    <br />
                                    <span>
                                        <b>
                                            <a href="mailto:hello@hackonnect.uz" className='email'>
                                                hello@hackonnect.uz
                                            </a>
                                        </b>
                                    </span>
                                    <br />
                                    <br />
                                    <p>
                                        Your vision, our expertise—let’s create something
                                        extraordinary together.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default customTransition(StartupWeekend);