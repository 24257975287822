import React, { useRef } from 'react'
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function StandardPage(props) {
    const { children } = props;
    const container = useRef();

    useGSAP(
        () => {
            gsap.set("main", {
                boxShadow: "0 0 30px #00000024",
            });
            gsap.to("main", {
                scrollTrigger: {
                    trigger: "main",
                    start: "top bottom",
                    end: "top top",
                    scrub: 1,
                    onScrubComplete: ({ isActive }) => {
                        if (!isActive && window.scrollY !== 0) {
                            document.querySelector("main").style.transform = null;
                        }
                    },
                    onRefresh: ({ isActive }) => {
                        if (!isActive && window.scrollY !== 0) {
                            document.querySelector("main").style.transform = null;
                        }
                    },
                },
                border: "3px solid rgba(0, 0, 0, 0.02)",
                boxShadow: "0 0 30px #00000000",
            });
            gsap.set("header", {
                backgroundColor: "#ffffff",
            });
            gsap.to("header", {
                scrollTrigger: {
                    trigger: "main",
                    // markers: true,
                    start: "top top+=20%",
                    end: "top top",
                    scrub: 1,
                },
                backgroundColor: "#dddddd",
                // filter: "blur(4px)",
            });

            gsap.set(".hover-block img", {
                x: "50%",
                y: "50%",
                xPercent: -50,
                yPercent: -50,
            });
        },
        { scope: container }
    );

    return (
        <div className="app" ref={container}>
            {children}
        </div>
    )
}

export default StandardPage