import React, { useRef } from 'react'
import Slider from "react-slick";
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from '@gsap/react';

import videosource from "../assets/video/haus-home-sequence.webm";
import uzum from "../assets/img/uzum.png";
import fao from "../assets/img/fao.png";
import undp from "../assets/img/undp.png";
import ebrd from "../assets/img/ebrd.png";
import cgiar from "../assets/img/cgiar.png";
import kursiv from "../assets/img/kursiv.png";
import hackathon1 from "../assets/img/hackathon_1.jpg";
import acceleration1 from "../assets/img/acceleration.webp";
import demoday1 from "../assets/img/demoday1.jpg";
import author from "../assets/img/sarvar.jpg";
import author2 from "../assets/img/author.jpg";
import dimadina from "../assets/img/team/dimadina2.jpg";
import aziza from "../assets/img/team/aziza.jpg";
import mahtob from "../assets/img/team/mahtob.jpg";
import mmadina from "../assets/img/team/mmadina.jpg";
import swu from "../assets/img/swu.jpg";
import techgenius from "../assets/img/techgenius.jpg";
import uzummeetup from "../assets/img/uzummeetup.jpg";
import digitalvalley from "../assets/img/digitalvalleycamp.jpg";
import customTransition from '../utils/transition';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

var settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

var companiesSliderSettings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
    ],
};

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function Home() {
    const container = useRef();

    useGSAP(
        () => {
            let mm = gsap.matchMedia();

            mm.add("(min-width: 768px)", () => {

                gsap.set(".hover-block img", {
                    x: "50%",
                    y: "50%",
                    xPercent: -50,
                    yPercent: -50,
                });

                let activeImage;
                gsap.utils.toArray(".hover-block").forEach((el) => {
                    let image = el.querySelector("img"),
                        setX,
                        setY,
                        align = (e) => {
                            setX(e.clientX);
                            setY(e.clientY);
                        },
                        startFollow = () => document.addEventListener("mousemove", align),
                        stopFollow = () => document.removeEventListener("mousemove", align),
                        fade = gsap.to(image, {
                            autoAlpha: 1,
                            ease: "none",
                            paused: true,
                            duration: 0.2,
                            onReverseComplete: stopFollow,
                        });

                    el.addEventListener("mouseenter", (e) => {
                        fade.play();
                        startFollow();
                        if (activeImage) {
                            gsap.set(image, {
                                x: gsap.getProperty(activeImage, "x"),
                                y: gsap.getProperty(activeImage, "y"),
                            });
                        }
                        activeImage = image;
                        setX = gsap.quickTo(image, "x", { duration: 0.6, ease: "power3" });
                        setY = gsap.quickTo(image, "y", { duration: 0.6, ease: "power3" });
                        align(e);
                    });

                    el.addEventListener("mouseleave", () => fade.reverse());
                });

                return () => {
                };
            });


        },
        { scope: container }
    );

    return (
        <div>
            <Header
                main={
                    <>
                        <div>Powering</div>
                        <div>
                            <span className="purple">connections</span>
                        </div>
                        <div className="">
                            Sparking <span className="blue">actions</span>
                        </div>
                    </>
                }
                action={
                    <Link to="/contact" className="btn primary">
                        how can we help you?
                    </Link>
                }
                bottom={
                    <>
                        Hackonnect is a company that creates dynamic events and programs
                        that catalyze effective decision making in the tech community
                    </>
                }
            />
            <main ref={container}>
                <section id="about" data-color="white black">
                    <div className="container">
                        <div className="split-row px-xl">
                            <div>
                                <video width="750" muted autoPlay loop>
                                    <source src={videosource} type="video/mp4" />
                                </video>
                            </div>
                            <div>
                                <h2>
                                    Hackonnect - Igniting{" "}
                                    <span className="bg-rounded bg-blue">Creativity</span> in the
                                    IT World
                                </h2>
                                <div className="split-content">
                                    <p>
                                        We believe that by connecting talented individuals and
                                        organizations through exceptional IT event management, we
                                        can unlock the potential to tackle real-world challenges and
                                        propel groundbreaking ideas forward.
                                        <br />
                                        <br />
                                        <b>Our Mission:</b>
                                        <br />
                                        <ul>
                                            <li>
                                                <b>Empower innovation:</b> We provide the platform and
                                                resources necessary for creative minds to collaborate
                                                and bring their most disruptive ideas to life.
                                            </li>
                                            <li>
                                                <b>Bridge connections:</b> We foster a collaborative
                                                environment where participants, sponsors, and mentors
                                                can connect, share knowledge, and build lasting
                                                relationships.
                                            </li>
                                            <li>
                                                <b>Drive meaningful outcomes:</b> We go beyond simply
                                                organizing events. Our focus is on delivering measurable
                                                results that contribute to the success of your IT
                                                initiatives.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section data-color="black white">
                    <div className="container">
                        <div className="split-row split-row-head align-items-end">
                            <div className="half-row">
                                {/* <div></div> */}
                                {/* <div>Services</div> */}
                            </div>
                            <div>
                                <h2>What we do:</h2>
                            </div>
                        </div>
                        <div className="service-block split-row">
                            <div>
                                <h3>Hackathon Organization</h3>
                                <div className="hover-block">
                                    <img src={hackathon1} alt="hackathon" />
                                </div>
                            </div>
                            <div className="split-content">
                                <h3>
                                    We excel in organizing hackathons across various IT
                                    specializations, including:
                                </h3>
                                <ul>
                                    <li>
                                        <b>Web Development & Design:</b> Help participants build
                                        innovative websites, mobile apps, or user interfaces under
                                        time pressure.
                                    </li>
                                    <li>
                                        <b>Cybersecurity:</b> Encourage teams to develop solutions
                                        for real-world cybersecurity challenges.
                                    </li>
                                    <li>
                                        <b>Data Science & AI:</b> Challenge participants to create
                                        data-driven solutions or leverage AI for specific
                                        applications.
                                    </li>
                                    <li>
                                        <b>Blockchain:</b> Facilitate hackathons focused on
                                        developing blockchain-based solutions for various
                                        industries.
                                    </li>
                                    <li>
                                        <b>Open Innovation:</b> Design hackathons around specific
                                        industry challenges, allowing companies to leverage the
                                        collective problem-solving power of participants.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="service-block split-row">
                            <div>
                                <h3>Acceleration & Incubation Programs</h3>
                                <div className="hover-block">
                                    <img src={acceleration1} alt="hackathon" />
                                </div>
                            </div>
                            <div className="split-content">
                                <h3>
                                    We design and manage programs tailored to IT startups,
                                    providing them with resources specific to their needs:
                                </h3>
                                <ul>
                                    <li>
                                        <b>Pre-Seed Acceleration:</b> Equip early-stage IT startups
                                        with the tools and network to validate their business ideas
                                        and secure initial funding.
                                    </li>
                                    <li>
                                        <b>Seed-Stage Incubation:</b> Offer dedicated workspace,
                                        mentorship, and investor connections to help IT startups
                                        develop their product or service and scale their business.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="service-block split-row">
                            <div>
                                <h3>Demo Days</h3>
                                <div className="hover-block">
                                    <img src={demoday1} alt="hackathon" />
                                </div>
                            </div>
                            <div className="split-content">
                                <h3>
                                    We create dynamic platforms for IT startups to showcase their
                                    ideas, catering to specific investor interests:
                                </h3>
                                <ul>
                                    <li>
                                        <b>Angel Investor Demo Days:</b> Connect promising
                                        early-stage startups with potential angel investors seeking
                                        high-growth opportunities.
                                    </li>
                                    <li>
                                        <b>Venture Capital Demo Days:</b> Facilitate pitches to
                                        venture capital firms interested in funding disruptive IT
                                        ventures.
                                    </li>
                                    <li>
                                        <b>Corporate Innovation Demo Days:</b> Organize events for
                                        established corporations to discover and potentially partner
                                        with innovative IT startups.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="service-block split-row">
                            <div>
                                <h3>Additional Services</h3>
                                <div className="hover-block">
                                    <img src={hackathon1} alt="hackathon" />
                                </div>
                            </div>
                            <div className="split-content">
                                <h3>
                                    We offer a range of services to complement your IT event
                                    needs, including:
                                </h3>
                                <ul>
                                    <li>
                                        <b>Marketing & Event Branding:</b> Develop a strong brand
                                        identity and create targeted marketing campaigns to attract
                                        the right participants and sponsors for your IT event.
                                    </li>
                                    <li>
                                        <b>Logistics & Management:</b> Handle all aspects of event
                                        planning and execution, from venue sourcing to technology
                                        setup and post-event reporting.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section data-color="black white">
                    <div className="container px-xl">
                        <h3>Our core values</h3>
                        <div className="infocards">
                            <div className="infocard">
                                <div className="infocard-head">01</div>
                                <div className="infocard-title">Passion</div>
                                <div className="infocard-description">
                                    We are driven by a genuine enthusiasm for fostering creativity
                                    and progress within the IT industry.
                                </div>
                            </div>
                            <div className="infocard">
                                <div className="infocard-head">02</div>
                                <div className="infocard-title">Excellence</div>
                                <div className="infocard-description">
                                    We strive to deliver exceptional service and experiences in
                                    everything we do.
                                </div>
                            </div>
                            <div className="infocard">
                                <div className="infocard-head">03</div>
                                <div className="infocard-title">Collaboration</div>
                                <div className="infocard-description">
                                    We believe that the power of collaboration is key to unlocking
                                    innovation and achieving success.
                                </div>
                            </div>
                            <div className="infocard">
                                <div className="infocard-head">04</div>
                                <div className="infocard-title">Community</div>
                                <div className="infocard-description">
                                    We are committed to building a strong and supportive community
                                    around IT innovation.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="team" data-color="white black">
                    <div className="container">
                        <div className="split-row split-row-head align-items-end">
                            <div className="half-row">
                                <div></div>
                                <div>
                                    <span className="helper-heading">Our Team</span>
                                </div>
                            </div>
                            <div>
                                <h2>
                                    Get to know <span className="bg-rounded bg-blue">people</span>{" "}
                                    that get it all done
                                </h2>
                            </div>
                        </div>
                        <div className="split-row align-items-end">
                            <div className="half-row"></div>
                            <div>
                                <div className="team-gallery">
                                    <div className="team-gallery-item">
                                        <img src={dimadina} alt="team photo" />
                                        <div>
                                            <div>
                                                <b>Madina Zokirova</b>
                                            </div>
                                            <div>CEO</div>
                                        </div>
                                    </div>
                                    <div className="team-gallery-item">
                                        <img src={aziza} alt="team photo" />
                                        <div>
                                            <div>
                                                <b>Madina Zokirova</b>
                                            </div>
                                            <div>Partner (Plug and Play)</div>
                                        </div>
                                    </div>
                                    <div className="team-gallery-item">
                                        <img src={mahtob} alt="team photo" />
                                        <div>
                                            <div>
                                                <b>Mahtob Asadova</b>
                                            </div>
                                            <div>Partner (MohirAI, Coca Cola)</div>
                                        </div>
                                    </div>
                                    <div className="team-gallery-item">
                                        <img src={mmadina} alt="team photo" />
                                        <div>
                                            <div>
                                                <b>Madina Mirzakhalilova</b>
                                            </div>
                                            <div>Program Manager</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="partners" data-color="white black">
                    <div className="container">
                        <div className="split-row split-row-head align-items-end pt-xl">
                            <div className="half-row">
                                <div></div>
                                <div>
                                    <span className="helper-heading">Hackonnections</span>
                                </div>
                            </div>
                            <div>
                                <h2>We exist one year, but already worked with</h2>
                            </div>
                        </div>
                        <div>
                            <Slider
                                {...companiesSliderSettings}
                                className="companies-slider px-xl"
                            >
                                <div className="companies-slider-item">
                                    <img src={uzum} />
                                </div>
                                <div className="companies-slider-item">
                                    <img src={fao} alt="fao" />
                                </div>
                                <div className="companies-slider-item">
                                    <img src={ebrd} alt="ebrd" />
                                </div>
                                <div className="companies-slider-item">
                                    <img src={cgiar} alt="cgiar" />
                                </div>
                                <div className="companies-slider-item">
                                    <img src={undp} alt="undp" />
                                </div>
                                <div className="companies-slider-item">
                                    <img src={kursiv} alt="kursiv" />
                                </div>
                            </Slider>
                        </div>

                        <div className="split-row">
                            <div className="half-row">
                            </div>
                            <div className="split-content">
                                <div>
                                    <Slider
                                        {...settings}
                                        className="testimonal-slider"
                                    >
                                        <div>
                                            <div>
                                                <h4>
                                                    “We were thoroughly impressed with Hackonnect’s event
                                                    planning. Their dedication and clear communication
                                                    ensured a successful outcome. Highly recommended!”
                                                </h4>
                                                <div className="testimonal-author-block">
                                                    <div className="testimonal-author-avatar">
                                                        <img src={author} alt="testimonal author" />
                                                    </div>
                                                    <div className="testimonal-author-description">
                                                        <div className="testimonal-author-name">
                                                            Sarvar Mutalov
                                                        </div>
                                                        <div className="testimonal-author-position">
                                                            CTO at Gloria Jeans
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <h4>
                                                    “Hackonnect’s expertise shines through in every
                                                    aspect. Their proactive problem-solving and regular
                                                    updates kept us informed and confident throughout the
                                                    process.”
                                                </h4>
                                                <div className="testimonal-author-block">
                                                    <div className="testimonal-author-avatar">
                                                        <img src={author2} alt="testimonal author" />
                                                    </div>
                                                    <div className="testimonal-author-description">
                                                        <div className="testimonal-author-name">
                                                            Server Japarov
                                                        </div>
                                                        <div className="testimonal-author-position">
                                                            Forward at Uzbekistan National Team
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="team" data-color="white black">
                    <div className="container">
                        <div className="split-row split-row-head align-items-end">
                            <div className="half-row">
                                <div></div>
                                <div>
                                    <span className="helper-heading">Our Work</span>
                                </div>
                            </div>
                            <div>
                                <h2>Composing Stellar Moments</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container wide">

                        <div>
                            <div className="work-gallery">
                                <Link to="/startupweekend" className="work-gallery-item">
                                    <div className="work-gallery-image bg-asterisk-blue">
                                        <img src={swu} alt="startup weekend uzbekistan" />
                                    </div>
                                    <div className="work-gallery-description">
                                        <span className="bg-rounded bg-blue">
                                            Startup Weekend Uzbekistan
                                        </span>
                                    </div>
                                </Link>
                                <div className="work-gallery-item">
                                    <div className="work-gallery-image bg-hc-pink">
                                        <img src={techgenius} alt="startup weekend uzbekistan" />
                                    </div>
                                    <div className="work-gallery-description">
                                        <span className="bg-rounded bg-pink">TechGenius</span>
                                    </div>
                                </div>
                                <div className="work-gallery-item">
                                    <div className="work-gallery-image bg-asterisk-pink">
                                        <img src={uzummeetup} alt="startup weekend uzbekistan" />
                                    </div>
                                    <div className="work-gallery-description">
                                        <span className="bg-rounded bg-pink">UZUM Tech Meetup</span>
                                    </div>
                                </div>
                                <div className="work-gallery-item">
                                    <div className="work-gallery-image bg-hc-blue">
                                        <img src={digitalvalley} alt="startup weekend uzbekistan" />
                                    </div>
                                    <div className="work-gallery-description">
                                        <span className="bg-rounded bg-blue">
                                            Digital Valley Camp
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section data-color="white black">
                    <div className="container">
                        <div className="split-row align-items-end pt-xl">
                            <div className="half-row">
                                {/* <div>☼</div> */}
                                <div>
                                    {/* <span className="helper-heading">Contact Us</span> */}
                                </div>
                            </div>
                            <div>
                                <h2>
                                    Ready to{" "}
                                    <span className="bg-rounded bg-blue">Spark Innovation?</span>
                                </h2>
                            </div>
                        </div>
                        <div className="split-row">
                            <div className='half-row'></div>
                            <div>
                                <div className="split-content">
                                    <p>
                                        Contact Hackonnect today to discuss your IT event needs.
                                        Let's collaborate on creating a successful and impactful
                                        program that propels innovation forward.
                                    </p>
                                    <br />
                                    <Link to="/contact" className="btn">
                                        let's collaborate
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contacts" data-color="white black">
                    <div className="container">
                        <div className="split-row split-row-head align-items-end pt-xl">
                            <div className="half-row">
                                <div></div>
                                <div>Contact us</div>
                            </div>
                            <div>
                                <h2 className="">Get in Touch</h2>
                            </div>
                        </div>
                        <div className="split-row">
                            <div className='half-row'></div>
                            <div>
                                <div className="split-content">
                                    <h4>
                                        Have an event in mind? We’re here to help bring it to life.
                                    </h4>
                                    <br />
                                    <span>
                                        <b>
                                            <a href="mailto:hello@hackonnect.uz" className='email'>
                                                hello@hackonnect.uz
                                            </a>
                                        </b>
                                    </span>
                                    <br />
                                    <br />
                                    <div>
                                        Your vision, our expertise—let’s create something
                                        extraordinary together.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default customTransition(Home);