import React, { useRef } from 'react'
import { useGSAP } from '@gsap/react';
import customTransition from '../utils/transition';
import Header from '../components/Header';



function StartupWeekend() {
    const container = useRef();

    useGSAP(
        () => {
        },
        { scope: container }
    );

    const submitForm = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        alert(data.get("email"));
    }

    return (
        <div>
            <main ref={container}>
                <div className='container'>
                    <div className='contact-wrapper'>
                        <div className='contact-block'>
                            <h1>Let's start a project</h1>
                            <form onSubmit={submitForm}>
                                <input name="name" placeholder='John Doe' required />
                                <input name="email" type="email" placeholder='Email' required />
                                <input name="phone" type="tel" placeholder='Phone number' required />
                                <input name="telegram" type="text" placeholder='Telegram username' required />
                                <input name="company" type="text" placeholder='Company' required />
                                <input name="job" type="text" placeholder='Job role' required />
                                <br />
                                <label>Event description:</label>
                                <br />
                                <input
                                    name="date"
                                    placeholder='Date of the event'
                                    type="date"
                                    // onFocus={(e) => {
                                    //     e.currentTarget.type = 'date';
                                    //     e.currentTarget.showPicker();
                                    // }}
                                    // onBlur={(e) => e.target.type = 'text'}
                                    required
                                />
                                <input name="targetAudience" type="text" placeholder='Target audience' required />
                                <select name="participantQuantity" type="text" placeholder='Participant quantity' required>
                                    <option value="" disabled selected>Participant quantity</option>
                                    <option>0-50 people</option>
                                    <option>51-100 people</option>
                                    <option>101-200 people</option>
                                    <option>201-500 people</option>
                                    <option>501-1000 people</option>
                                    <option>1000+ people</option>
                                </select>
                                <input name="goal" type="text" placeholder='Main goal of the event' required />
                                <select name="budget" type="text" placeholder='Project budget' required>
                                    <option value="" disabled selected>Project budget</option>
                                    <option>0-50 people</option>
                                    <option>51-100 people</option>
                                    <option>101-200 people</option>
                                    <option>201-500 people</option>
                                    <option>501-1000 people</option>
                                    <option>1000+ people</option>
                                </select>
                                <br />
                                <button type="submit" className='btn'>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default customTransition(StartupWeekend);