import React from 'react'
import logo_black from "../assets/img/logo_black.svg";

function Footer() {
    return (
        <footer>
            <div className="container">
                <div>
                    <img src={logo_black} className="logo" />
                </div>
                <div className="skip"></div>
                <div className='credits'>
                    <div>All rights reserved.</div>
                    <div>© {new Date().getFullYear()} Hackonnect</div>
                    <div>
                        <a href="https://www.linkedin.com/in/mutalov/" className="credit">
                            website by Sarvar Mutalov
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer