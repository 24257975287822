import React, { useRef } from 'react'
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import { Link } from 'react-router-dom';

function Header(props) {
    const header = useRef();
    const heading = useRef();
    const { main, action, bottom } = props;

    useGSAP(
        () => {
            gsap.to(header.current, {
                scrollTrigger: {
                    trigger: header.current,
                    start: "top top",
                    end: "bottom top",
                    pin: header.current,
                    pinSpacing: false,
                    scrub: 2,
                },
            });
        },
        { scope: header }
    );
    useGSAP(
        () => {
            gsap.to("header", {
                scrollTrigger: {
                    trigger: "header",
                    start: "top top",
                    end: "bottom top",
                    pin: "header",
                    anticipatePin: 1,
                    pinSpacing: false,
                    scrub: 1,
                },
            });
            gsap.set("header", {
                backgroundColor: "#ffffff",
            });
            gsap.to("header", {
                scrollTrigger: {
                    trigger: "main",
                    start: "top bottom-=20%",
                    end: "top top",
                    scrub: 1,
                },
                backgroundColor: "#dddddd",
            });

            gsap.to("header", {
                scrollTrigger: {
                    trigger: "main",
                    // markers: true,
                    start: "top top+=20%",
                    end: "top top",
                    scrub: 1,
                },
                backgroundColor: "#dddddd",
                // filter: "blur(4px)",
            });
        },
        { scope: heading }
    );

    return (
        <header className="header" ref={header}>
            <div className="heading container" ref={heading}>
                <div className="heading_text">
                    {main}
                </div>
                {action && <div>{action}</div>}
            </div>
            {bottom && (
                <div className="bottom container">
                    <div>
                        {bottom}
                    </div>
                </div>
            )}
        </header>
    )
}

export default Header