import React from 'react'
import logo_black from "../assets/img/logo_black.svg";
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <div className="navbar_wrapper">
            <div className="container">
                <div className="navbar">
                    <div>
                        <Link to={"/"}>
                            <img src={logo_black} className="logo" />
                        </Link>
                    </div>
                    <nav>
                        <a href="#about">About</a>
                        <a href="#partners">Partnerships</a>
                        <a href="#contacts">Contacts</a>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Navbar