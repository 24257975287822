import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import StandardPage from "./components/common/StandardPage";
import "./App.css";
import "./fonts.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Route,
  Routes,
  ScrollRestoration,
  useLocation,
  useOutlet,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./pages/Home";
import StartupWeekend from "./pages/StartupWeekend";
import Contact from "./pages/Contact";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function App() {
  const location = useLocation();

  return (
    <StandardPage>
      <Navbar />
      <AnimatePresence
        mode="wait"
        onExitComplete={() => {
          if (typeof window !== "undefined") {
            window.scrollTo({ top: 0, behavior: "instant" });
          }
        }}
      >
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/startupweekend" element={<StartupWeekend />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </StandardPage>
  );
}

export default App;
